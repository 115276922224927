import React from 'react';
import { Link, graphql } from 'gatsby';
//import Img from 'gatsby-image'
import Layout from '../components/layout';
import Definitions from '../common/definitions';

const StateTemplate = ({ data }) => (
  <Layout>
    <h1>{data.strapiStates.country.DistrictWord} in {data.strapiStates.StateName}</h1>
    <div><Link to={Definitions.GalleryPagesBasePath.concat(data.strapiStates.country.slug.toLowerCase(),'/')}>zurück</Link></div>
    <ul>
      {data.strapiStates.districts.map(
          (district) => {
          return(
            <li key={district.id}><Link to={Definitions.GalleryPagesBasePath.concat(
              data.strapiStates.country.slug.toLowerCase(),'/',
              data.strapiStates.slug.toLowerCase(),'/',
              district.slug.toLowerCase(),'/')}
              >{district.DistrictName}</Link>
              &nbsp;({getNumberOfCitiesInDistrict(data, district.id)})
            </li>
          );
        })
      }
    </ul>
  </Layout>
);

export default StateTemplate;

const getNumberOfCitiesInDistrict = (data, districtId) => {
  let count = 0;
  for (let cityIdx = 0; cityIdx < data.allStrapiCities.edges.length; cityIdx++) {
    if (data.allStrapiCities.edges[cityIdx].node.district.id === districtId) {
      count++;
    }
  }
  return count;
}

export const query = graphql`
  query StateTemplate($id: String!) {
    strapiStates(id: {eq: $id}) {
      StateName
      strapiId
      id
      slug
      country {
        slug
        CountryName
        DistrictWord
      }
      districts {
        DistrictName
        state
        id
        slug
      }
    }
    allStrapiCities {
      edges {
        node {
          id
          district {
            id
          }
        }
      }
    }
  }
`;